<template>
  <b-container>
    <b-row>
      <b-col md="3">
        <template v-if="$options.components['bill-payment-category']">
          <bill-payment-category />
        </template>
      </b-col>
      <b-col md="9">
        <span class="text-grey1">Bill Payment > DTH Payment</span>
        <b-row class="mt-4">
          <b-col md="7">
            <div data-app class="text-center billpayment-tabsec m-4">
              <form>
                <div>
                  <template>
                    <model-select
                      :options="getValue"
                      v-model="selected"
                      class="select-style mb-4"
                      placeholder="Your Selected DTH Service"
                    >
                    </model-select>
                  </template>
                </div>
                <v-text-field
                  v-model="number"
                  label="Enter Subscriber ID/ Registerd Mobile Number*"
                ></v-text-field>
                <div class="buttondv mt-4">
                  <button
                    type="button"
                    class="btn btn-outline-primary mb-2 mr-2"
                  >
                    Clear
                  </button>
                  <button
                    type="button"
                    @click="gotoRechargepage"
                    class="btn btn-primary mb-2 ml-2"
                  >
                    Continue
                  </button>
                  <!-- </router-link> -->
                </div>
              </form>
            </div>
          </b-col>
          <!-- <b-col md="5 mb-4">
            <div>
              <h5 class="mb-4">Upcoming Action</h5>
              <template v-if="$options.components['bill-payment-action-transaction']">
                <bill-payment-action-transaction />
              </template>
            </div>
            <div>
              <h5 class="mt-4 mb-4">Recent Transaction</h5>
              <template v-if="$options.components['bill-payment-action-transaction']">
                <bill-payment-action-transaction />
              </template>
            </div>
          </b-col>
          <b-col md="12" class="mt-4 latest-offer-div">
            <h4 class="mb-4 mt-3">Latest Offers</h4>
            <template v-if="$options.components['bill-payment-latest-offers']">
              <bill-payment-latest-offers />
            </template>
          </b-col>-->
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapState } from "vuex";
import { ModelSelect } from "vue-search-select";
export default {
  name: "dth-recharge",
  components: {
    ModelSelect,
  },
  data() {
    return {
      name: "",
      number: "",
      value1: "",
      operatorName: "",
      selected: { value: null },
    };
  },
  computed: {
    ...mapState({
      DTHserviceList: (state) => state.billPayment.DTHserviceList,
      marketList: (state) => state["home"].marketList,
    }),
    getValue() {
      return this.DTHserviceList.map((e) => {
        return {
          icon: `https://static.mobikwik.com/appdata/operator_icons/op${e.operatorid}.png`,
          value: e.operatorid,
          text: e.operatorname,
        };
      });
    },
  },
  methods: {
    gotoRechargepage() {
      this.value1 = this.selected.value;
      const data = this.DTHserviceList.filter(
        (e) => e.operatorid == this.value1
      );
      this.name = data[0].operatorname;
      this.$router.push({
        name: `dth-pay`,
        params: {
          number: this.number,
          id: this.value1,
          name: this.name,
          ca_id: this.$route.params.id,
          category: this.$route.params.category,
        },
      });
      //   this.$router.push(
      //     `/bill_payment/dth-pay/${this.name}/${this.value1}/${this.number}`
      //   );
    },
  },
  async mounted() {
    await this.$store.dispatch("billPayment/fetchDTHData");
  },
};
</script>
<style scoped>
.billpayment-tabsec .v-list {
  text-align: left;
}
.buttondv .btn-outline-primary {
  width: 120px;
}

.buttondv .btn-primary {
  width: 120px;
}
.latest-offer-div {
  background-color: #f8f8f8;
}
</style>
