var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm.$options.components['bill-payment-category'] ? [_c('bill-payment-category')] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('span', {
    staticClass: "text-grey1"
  }, [_vm._v("Bill Payment > DTH Payment")]), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "text-center billpayment-tabsec m-4",
    attrs: {
      "data-app": ""
    }
  }, [_c('form', [_c('div', [[_c('model-select', {
    staticClass: "select-style mb-4",
    attrs: {
      "options": _vm.getValue,
      "placeholder": "Your Selected DTH Service"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })]], 2), _c('v-text-field', {
    attrs: {
      "label": "Enter Subscriber ID/ Registerd Mobile Number*"
    },
    model: {
      value: _vm.number,
      callback: function callback($$v) {
        _vm.number = $$v;
      },
      expression: "number"
    }
  }), _c('div', {
    staticClass: "buttondv mt-4"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary mb-2 mr-2",
    attrs: {
      "type": "button"
    }
  }, [_vm._v(" Clear ")]), _c('button', {
    staticClass: "btn btn-primary mb-2 ml-2",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.gotoRechargepage
    }
  }, [_vm._v(" Continue ")])])], 1)])])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }